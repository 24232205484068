<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">

        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li><a href="#"> <i class="uil-home-alt"></i> </a></li>
              <li>{{$t('question.questions')}}</li>
              <li>{{$t('question.add')}}</li>
            </ul>
          </nav>
        </div>

        <div class="card">
          <div v-for="error in formErrors" v-if="formErrors" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color:red;">
              <i class="uil-exclamation-triangle"></i> {{ error }} </p>
          </div>
          <div v-if="successMessage" class="uk-alert-success" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color:green;">
              <i class="uil-exclamation-triangle"></i> {{ successMessage }} </p>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-body">
            <h4><i class="uil-question-circle"></i> {{$t('question.add')}}</h4>
            <hr>
            <div class="row">
              <div class="col-12" v-if="loading">
                <default-loading></default-loading>
              </div>
              <div class="col-xl-9 m-auto" v-else>
                <div class="form-group row mb-3">
                  <label class="col-md-3 col-form-label" for="type">{{$t('question.attributes.q_type')}} <span class="required">*</span></label>
                  <div class="col-md-9">
                    <select @change="formTypeControl" class="selectpicker1" v-model="form.type" id="type">
                      <option v-for="type in question_types" :value="type.id"><i :class="type.icon"></i> {{type.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mb-3" v-if="form.type == 3 || form.type == 5">
                  <label class="col-md-3 col-form-label">{{$t('question.attributes.image')}} <span class="required">*</span></label>
                  <div class="col-md-9">
                    <div class="uploadOuter">
                      <label for="questionFileUpload" class="btn btn-info"><i class="uil-upload"></i>{{$t('general.upload')}}</label>
                      <strong>{{$t('general.or')}}</strong>
                      <span class="dragBox">
                          <i class="icon-feather-file-plus"></i>
                          {{$t('general.doc_file_drag_drop')}}
                          <input accept="image/*" type="file" @change="dragNdrop($event)" id="questionFileUpload">
                        </span>
                    </div>
                    <div id="preview" class="filePreview" v-html="preview"></div>
                  </div>
                </div>

                <div class="form-group row mb-3" v-if="form.type == 7">
                  <label class="col-md-3 col-form-label">{{$t('general.audio_file')}} <span class="required">*</span></label>
                  <div class="col-md-9">
                    <div class="uploadOuter">
                      <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i>{{$t('general.upload')}}</label>
                      <strong>{{$t('general.or')}}</strong>
                      <span class="dragBox">
                          <i class="icon-feather-file-plus"></i>
                          {{$t('general.audio_file_drag_drop')}}
                          <input accept="audio/*" type="file" @change="dragNdropAudio($event)" id="uploadFile">
                        </span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div v-if="audioPreview" id="preview" v-html="audioPreview"></div>
                  </div>
                </div>

                <div class="form-group row mb-3" v-if="form.type == 8">
                  <label class="col-md-3 col-form-label">{{$t('general.video_file')}} <span class="required">*</span></label>
                  <div class="col-md-9">
                    <div class="uploadOuter">
                      <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i>{{$t('general.upload')}}</label>
                      <strong>{{$t('general.or')}}</strong>
                      <span class="dragBox">
                          <i class="icon-feather-file-plus"></i>
                          {{$t('general.video_file_drag_drop')}}
                          <input accept="video/*" type="file" @change="dragNdropVideo($event)" id="uploadFile">
                        </span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div v-if="videoPreview" id="preview" v-html="videoPreview"></div>
                  </div>
                </div>

                <div class="form-group row mb-3">
                  <label class="col-md-3 col-form-label" for="question_text">{{$t('question.attributes.text')}} <span class="required">*</span></label>
                  <div class="col-md-9">
                    <textarea name="question_text" id="question_text" v-model="form.text" :placeholder="$t('question.attributes.text')" class="form-control"></textarea>
                  </div>
                </div>

                <div class="form-group row mb-3" v-if="form.type == 6">
                  <label class="col-md-3 col-form-label" for="answer_text">{{ $t('question.attributes.answer') }}<span class="required">*</span></label>
                  <div class="col-md-9">
                    <textarea name="question_text" v-model="form.answers[0].name" id="answer_text" :placeholder="$t('question.attributes.answer')" class="form-control"></textarea>
                  </div>
                </div>

                <div class="form-group row mb-3" v-if="form.type != 6">
                  <label class="col-md-3 col-form-label" for="replies">{{$t('question.attributes.answer_options')}} <span class="required">*</span></label>
                  <div class="col-md-9">
                    <table id="myTable" class=" table order-list">
                      <thead>
                      <tr>
                        <td>{{$t('question.attributes.answer_text')}}</td>
                        <td class="text-center">{{ $t('general.status') }}</td>
                        <td class="text-right">{{ $t('general.action') }}</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in form.answers">
                        <td class="col-sm-4">
                          <div class="form-group row mb-3" v-if="form.type == 4 || form.type == 5">
                            <div class="col-md-12">
                              <div class="uploadOuter">
                                <label :for="'uploadFileReply'+index" class="btn btn-info"><i class="uil-upload"></i>{{ $t('general.upload') }}</label>
                                <br>
                                <strong>{{$t('general.or')}}</strong>
                                  <span class="dragBox">
                                    <i class="icon-feather-file-plus"></i>
                                    {{$t('general.doc_file_drag_drop')}}
                                    <input accept="image/*" type="file" :data-index="index" ref="replies" @change="dragNdropReply($event)" :id="'uploadFileReply'+index">
                                  </span>
                              </div>
                              <div id="preview" :class="'preview_' + index" v-html="previewReply[index]"></div>
                            </div>
                          </div>
                          <input v-if="questionTypeCheck(form.type)" type="text" required name="reply_text" v-model="item.name" class="form-control" />
                        </td>
                        <td class="col-sm-4">
                          <div class="d-flex align-content-center justify-content-center table-vertical-center align-items-center">
                            <toggle-button :width="80" v-model="item.answer" color="#ae71ff" :sync="true" :labels="{checked: $t('question.attributes.true'), unchecked: $t('question.attributes.false')}"/>
                          </div>
                        </td>

                        <td class="col-sm-4">
                          <div class="actions mr-2 float-right">
                            <a href="javascript:void(0)" @click="removeRow(index)" :uk-tooltip="$t('general.remove_from_list')" class="btn btn-icon btn-hover btn-lg btn-circle" title="" aria-expanded="false">
                              <i class="uil-times-circle text-danger"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="5" style="text-align: left;">
                          <input @click="addRow" type="button" class="btn btn-lg btn-outline-primary" id="addrow" :value="$t('general.add')" />
                        </td>
                      </tr>
                      <tr>
                      </tr>
                      </tfoot>
                    </table>
                  </div>


                </div>
                <div class="uk-card-footer float-right pb-0 pr-0">
                  <button class="uk-button uk-button-primary small" type="button" @click="saveForm();"><i class="icon-feather-arrow-right"></i>
                    {{ $t('general.save') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import module, { BASE_URL, CREATE_ITEM, GET_QUESTION_TYPES, QUESTION_TYPES, QUESTION_TYPE_URL, ERROR, MODULE_NAME, LOADING } from "@/core/services/store/question.module";
import store from "@/core/services";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

export default {
  name: "QuestionAdd",
  components: {DefaultLoading},
  beforeCreate () {
    function registerStoreModule (moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(MODULE_NAME, module)
  },
  data() {
    return {
      preview : null,
      audioPreview : null,
      videoPreview : null,
      previewReply: [],
      form : {
        type : 1,
        text : '',
        media : '',
        answers : [
          {name: "", answer:false}
        ],
      },
      formErrors : [],
      successMessage : null,
    }
  },
  computed: {
    question_types: {
      get() {
        return store.getters[MODULE_NAME + '/' + QUESTION_TYPES]
      },
      set(value) {
      }
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    loading() {
      return store.getters[MODULE_NAME + '/' + LOADING]
    },
  },
  methods: {
    saveForm(){
      let self = this;
      let form = self.form;

      let answer_true_count = 0;
      form.answers.forEach(item => {
        if(item.answer){
          answer_true_count++;
        }
      });

      if (form.type == 1){
        if (answer_true_count == 0){
          self.formErrors.push(self.$t('question.required.true_count_only_one'));
          self.scrollToTop()
          return false;
        }
      }
      if (answer_true_count > 1){
        self.formErrors.push(self.$t('question.required.true_count'));
        self.scrollToTop()
        return false;
      }


      let formData = new FormData();
      let form_answers = JSON.stringify(form.answers);

      formData.append('question_type_id', form.type);
      formData.append('text', form.text);
      formData.append('answers', form_answers);
      formData.append('media', form.media);

      if (form.type == 4 || form.type == 5){
        $(form.answers).each(function (index,val) {
          formData.append('replyImages[]', val.media)
        })
      }

      store.dispatch(MODULE_NAME + '/' + CREATE_ITEM, {
        url: BASE_URL,
        contents: formData,
        returnType: "stateless",
        acceptPromise: true,
      }).then(() => {
        if (!this.errors) {
          this.$router.push({'name': 'company.questions.list'})
        }
      });
    },
    dragNdrop(event) {
      let file = event.target.files[0];
      this.form.media = file;

      this.preview = '<div class="file-info">' +
          '<div>Format: <span>'+file.type+'</span></div>' +
          '<div>Doküman Adı: <span>'+file.name+'</span></div>' +
          '<div>Boyut: <span>'+this.bytesToSize(file.size)+'</span></div>' +
          '</div><br>' +
          '<div><img src="'+URL.createObjectURL(file)+'" /></div>';
    },
    dragNdropReply(event) {
      let self = this;
      let file = event.target.files[0];
      let blob = URL.createObjectURL(file);
      let html = '<div class="file-info">' +
          '<div>Format: <span>'+file.type+'</span></div>' +
          '<div>Doküman Adı: <span>'+file.name+'</span></div>' +
          '<div>Boyut: <span>'+self.bytesToSize(file.size)+'</span></div>' +
          '</div><br>' +
          '<div><img src="'+blob+'" /></div>';

      $('.preview_' + event.target.getAttribute('data-index')).html(html);
      self.form.answers[event.target.getAttribute('data-index')].media = file;
    },
    dragNdropAudio(event){
      let self = this;
      let file = event.target.files[0];
      self.form.media = file;

      let html = '<div class="file-info">' +
          '<div>Format: <span>'+file.type+' <i class="uil-microphone" style="font-size: 14px"></i></span></div>' +
          '<div>Ses Dosyası Adı: <span>'+file.name+'</span></div>' +
          '<div>Boyut: <span>'+self.bytesToSize(file.size)+'</span></div>' +
          '</div>';

      self.audioPreview = html;
    },

    dragNdropVideo(event){
      let self = this;
      let file = event.target.files[0];
      self.form.media = file;

      let html = '<div class="file-info">' +
          '<div>Format: <span>'+file.type+' <i class="uil-video" style="font-size: 14px"></i></span></div>' +
          '<div>Video Dosyası Adı: <span>'+file.name+'</span></div>' +
          '<div>Boyut: <span>'+self.bytesToSize(file.size)+'</span></div>' +
          '</div>';

      self.videoPreview = html;
    },
    addRow(){
      this.form.answers.push({
        name: "",
        answer: false
      });
    },
    removeRow(index){
      this.form.answers.splice(index, 1);
    },
    questionTypeCheck(question_type){
      switch (question_type) {
        case 1 :
        case 2 :
        case 3 :
        case 7 :
        case 8 : return true;
      }
      return false;
    },
    formTypeControl(){
      this.form = {
        type : this.form.type,
        text : '',
        media : '',
        answers : [{name: "", answer:true}],
      }

      this.preview = null;
      this.previewReply = [];
      this.audioPreview = null;
      this.videoPreview = null;
      $("div[class^='preview_']").html("");
      $('input[type="file"]').val("");
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  mounted() {
    store.dispatch(MODULE_NAME + '/' + GET_QUESTION_TYPES, {
      url: QUESTION_TYPE_URL,
    })
    setTimeout(function () {
      $('.selectpicker').selectpicker();
    },500)
  }
};
</script>
<style scoped>
.uk-alert{
  margin: 0;
}
</style>
